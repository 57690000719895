import React from 'react'
import sh1 from './img/polygon-a.png'
import sh2 from './img/shape-i.png'
import sh3 from './img/triangle-a.png'
import sh4 from './img/polygon.png'

function Background() {
  return (
    <div className='andiv' >
          <div className='shps nn'>
            <img className='shpsimg' src={sh1} alt='' />
            <img className='imgshp' src={sh2} alt='' />
            <img className='shpsimg' src={sh3} alt='' />
            <img className='shpsimg' src={sh4} alt='' />
          </div>
          <div className='shps nn'>
            <img className='shpsimg' src={sh1} alt='' />
            <img className='imgshp' src={sh2} alt='' />
            <img className='shpsimg' src={sh3} alt='' />
            <img className='shpsimg' src={sh4} alt='' />
          </div>
          <div className='shps nn'>
            <img className='shpsimg' src={sh1} alt='' />
            <img className='imgshp' src={sh2} alt='' />
            <img className='shpsimg' src={sh3} alt='' />
            <img className='shpsimg' src={sh4} alt='' />
          </div>
          <div className='shps'>
            <img className='shpsimg' src={sh1} alt='' />
            <img className='imgshp' src={sh2} alt='' />
            <img className='shpsimg' src={sh3} alt='' />
            <img className='shpsimg' src={sh4} alt='' />
          </div>
          <div className='shps'>
            <img className='shpsimg' src={sh1} alt='' />
            <img className='imgshp' src={sh2} alt='' />
            <img className='shpsimg' src={sh3} alt='' />
            <img className='shpsimg' src={sh4} alt='' />
          </div>
          <div className='shps'>
            <img className='shpsimg' src={sh1} alt='' />
            <img className='imgshp' src={sh2} alt='' />
            <img className='shpsimg' src={sh3} alt='' />
            <img className='shpsimg' src={sh4} alt='' />
          </div>
    </div>
  )
}

export default Background