import Lottie from 'lottie-react'
import React from 'react'
import an from '../asset/notver.json'
import { sendEmailVerification, signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'



function Notver() {

    const navigate = useNavigate()

    const resend =()=>{
        sendEmailVerification(auth.currentUser).then(()=>{
            toast.success('link sent')
            navigate('/')
        })
    }

  return (
    <div className='verbox'>
        <ToastContainer/>
        <div className='notverbod'>
            <Lottie className='notveranim' animationData={an} />

            <h3>Email Verification</h3>
            <p>You have been sent an email. After verification reload this page </p>

            <button onClick={resend} className='logbtn' >RESEND</button>
            <button onClick={()=>signOut(auth)} className='regbtn lgbtnss'>LOGOUT</button>
        </div>
    </div>
  )
}

export default Notver