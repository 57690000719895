import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "firebase/compat/auth";
import {getStorage} from 'firebase/storage';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyDRzvPZqfpOM3_hUFyZ5FVfKdz6HbUZAl0",
  authDomain: "capitradx.firebaseapp.com",
  projectId: "capitradx",
  storageBucket: "capitradx.appspot.com",
  messagingSenderId: "569644855242",
  appId: "1:569644855242:web:ec552a857c6b6fa2d8a933",
  measurementId: "G-14HGSF36MP"
};


const app = firebase.initializeApp(firebaseConfig)

export const db = app.firestore()

export  const auth = app.auth()

export const storage = getStorage(app)

export const dbauth = getAuth(app)

export const Provider = new firebase.auth.GoogleAuthProvider()


const apk = firebase.initializeApp(firebaseConfig)

export default apk